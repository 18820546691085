import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AlarmIcon from '@material-ui/icons/Alarm';
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Service Information</h2>
          <h5 className={classes.description}>
          Primarily servicing Lenawee County, Skeels Ridesharing is committed to our community. From another familiar name, Skeels Lawncare, we want to expand the scope of our services to provide convenient and affordable rides. Let us know how we can resolve your needs. We guarantee faceless corporations cannot live up to our flexibility. 
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Comfortable"
              description="Our standards for every riders' experience will never be compromised. Health and safety are particularly emphasized to ensure peace of mind. "
              icon={EmojiTransportationIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Convenient"
              description="View live driver tracking, price estimations, and even book ahead of time! We have every avenue covered with every latest technology."
              icon={AlarmIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Secure"
              description="Aside from cash, credit card payments are PCI DSS compliant. The website has an SSL certification, and the apps are screened by the Apple App and Google Play stores' security teams. In app, there is a panic button for immediate help for an urgent issue (always dial 911 in case of emergency)."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
