export const MainConfig = {
    AppDetails: {
        app_name: "Skeels Ridesharing", 
        app_description: "Skeels Ridesharing App",
        app_identifier: "com.skeelsridesharing.app",
        ios_app_version: "1.0", 
        android_app_version: 10
    },
    FirebaseConfig: {
        apiKey: "AIzaSyCA-eGi6Mil3wfbw_MTw6wDbHeCBACDQf4",
        authDomain: "skeelsridesharing.firebaseapp.com",
        databaseURL: "https://skeelsridesharing.firebaseio.com",
        projectId: "skeelsridesharing",
        storageBucket: "skeelsridesharing.appspot.com",
        messagingSenderId: "19075543647",
        appId: "1:19075543647:web:5cd36f49b47876226521ea",
        measurementId: "G-3V144H5QMX"
    },
    Google_Map_Key: "AIzaSyC0IZPL55Iwi9fYY9hMYsAuu6WAgDQjYD4",
    facebookAppId:"206739744293226"
}