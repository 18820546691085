import logo72x72 from "./logo72x72.png";
import logo96x96 from "./logo72x72.png";
import logo192x192 from "./logo192x192.png";
import logoblack from "./logoblack.png";
import logowhite from "./logowhite.png";
import logo165 from "./logo165.png"
import logo1024x1024 from "./logo1024x1024.png";
import intro from "./intro.jpg";
import bg from "./bg.jpg";

export default {
    logo72x72,
    logo96x96,
    logo192x192,
    logoblack,
    logowhite,
    logo1024x1024,
    logo165,
    intro,
    bg
}